import React from "react";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { SingleAttachmentPreview } from "../../../../../components/SingleAttachmentPreview";

import UploadFile from "../../../../../components/UploadFile";
import { toast } from "react-toastify";

export const UserManual = ({ value, onChange }) => {
  const removeAttachment = () => {
    onChange("value.userManual", { fileId: null });
  };

  return (
    <Card>
      <CardHeader>Dane Ogólne</CardHeader>
      <CardBody>
        <UploadFile
          {...{
            baseUrl: "/api/files",
            acceptFileType: "application/pdf",
            acceptExtensionFiles: ".pdf",
            onDrop: Function.prototype,
            onCancel: Function.prototype,
            onDropAccepted: (value) => {
              onChange("value.userManual", { fileId: value.id });
            },
            onDropRejected: () => {
              toast.error(
                "Niepoprawny format pliku. Wymagany format pliku to .pdf"
              );
            },
          }}
        />
        {value?.userManual?.fileId ? (
          <Row>
            <Col lg={4} sm={6} xs={12}>
              <SingleAttachmentPreview
                {...{
                  isRemovable: !!value?.userManual?.fileId,
                  attachmentName: "Instrukcja obsługi BIP.pdf",
                  attachmentId: value?.userManual?.fileId,
                  removeAttachment,
                }}
              />
            </Col>
          </Row>
        ) : null}
      </CardBody>
    </Card>
  );
};
