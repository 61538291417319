import React, { Fragment, useState } from "react";

import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import orderBy from "lodash.orderby";

import FileListContainer from "../../../../../components/SortableList/FileListGroupContainer";
import { FileType } from "../../../../../helpers/enums";
import UploadAttachment from "../../../../../components/UploadAttachment";
import { fileUploadError } from "../../../../../helpers/error-message";
import ConfirmModal from "../../../../../components/ConfirmModal";

const SortOptions = [
  {
    display: "Rosnąco po nazwie wyświetlanej",
    column: "displayName",
    sort: "asc",
  },
  {
    display: "Malejąco po nazwie wyświetlanej",
    column: "displayName",
    sort: "desc",
  },
  {
    display: "Rosnąco po dacie dodania",
    column: "createdDate",
    sort: "asc",
  },
  {
    display: "Malejąco po dacie dodania",
    column: "createdDate",
    sort: "desc",
  },
  {
    display: "Rosnąco po rozmiarze",
    column: "size",
    sort: "asc",
  },
  {
    display: "Malejąco po rozmiarze",
    column: "size",
    sort: "desc",
  },
];

const ArticleAttachments = ({
  disabled = false,
  isAllowAddNewAttachments = false,
  prefixName = "",
  items = [],
  multiple = false,
  maxFileSize = 10,
  baseUrl = "#",
  acceptFileType,
  acceptExtensionFiles = "",
  additionalInfo = "",
  fileType = FileType.Document,
  customErrorExtensionMessage = "",
  onAdd,
  onRemove,
  onMove,
  onChange,
  setFieldValue,
  addedAttachments,
  publishedArticle,
  workingArticle,
  newAttachments,
  maxNewAttachmentCount,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  return (
    <Fragment>
      <ConfirmModal
        {...{
          open: showConfirm,
          input: false,
          title: "Potwierdzenie usunięcia załącznika",
          content: "Czy na pewno chcesz usunąć załącznik",
          onCancel: () => {
            setShowConfirm(false);
            setCurrentFileIndex(null);
          },
          onSubmit: () => {
            setShowConfirm(false);
            setCurrentFileIndex(null);
            onRemove(currentFileIndex);
          },
        }}
      />
      <Row>
        <Col ld={3} md={12}>
          <UploadAttachment
            disabled={disabled && !isAllowAddNewAttachments}
            maxSize={maxFileSize}
            multiple={multiple}
            acceptFileType={acceptFileType}
            acceptExtensionFiles={acceptExtensionFiles}
            additionalInfo={additionalInfo}
            onDrop={Function.prototype}
            baseUrl={baseUrl}
            fileType={fileType}
            onDropAccepted={onAdd}
            onDropRejected={(file) =>
              fileUploadError(file, maxFileSize, customErrorExtensionMessage)
            }
            onCancel={Function.prototype}
            newAttachments={newAttachments}
            publishedArticle={publishedArticle}
            maxNewAttachmentCount={maxNewAttachmentCount}
          />
        </Col>
      </Row>
      {items && items.length >= 2 && (
        <Row>
          <Col>
            <InputGroup className="justify-content-end">
              <UncontrolledButtonDropdown>
                <DropdownToggle caret color="info">
                  Sortowanie
                </DropdownToggle>
                <DropdownMenu>
                  {SortOptions.map((item) => (
                    <DropdownItem
                      onClick={(_) => {
                        const newItems = orderBy(
                          items,
                          [item.column],
                          [item.sort]
                        );

                        setFieldValue("attachments", newItems);
                      }}
                    >
                      {item.display}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </InputGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FileListContainer
            disabled={disabled}
            columns={[
              { name: "Nazwa pliku", class: "widget-heading flex-basis-50" },
              {
                name: "Nazwa wyświetlana",
                class: "widget-heading flex-basis-50",
              },
              {
                name: "Akcja",
                class:
                  "widget-heading widget-content-right flex-basis-10 text-center",
              },
            ]}
            items={items}
            baseUrl={baseUrl}
            prefixName={prefixName}
            removeable={true}
            selectable={false}
            onRemoveItem={(index) => {
              setCurrentFileIndex(index);
              setShowConfirm(true);
            }}
            onMoveItem={onMove}
            onChangeItem={onChange}
            shouldCancelStart={false}
            setFieldValue={setFieldValue}
            addedAttachments={addedAttachments}
            publishedArticle={publishedArticle}
            workingArticle={workingArticle}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ArticleAttachments;
