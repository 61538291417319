import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { Switch } from "../../../Shared/Switch";

export const GA = ({ value, displayName, onChange }) => {
  return (
    <Card>
      <CardHeader>{displayName}</CardHeader>
      <CardBody>
        <Row>
          <Col lg={12}>
            <Switch
              label={`Aktywny`}
              onClick={(e) => {
                onChange("value.isActive", !value.isActive);
              }}
              on={value.isActive}
              off={!value.isActive}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup>
              <Label>Identyfikator Google Analitycs</Label>
              <Input
                {...{
                  name: "value.gaid",
                  value: value.gaid,
                  onChange: (e) => {
                    onChange("value.gaid", e.target.value);
                  },
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
