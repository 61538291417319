import React, { useEffect, useState } from "react";
import { FieldArray } from "formik";
import { v4 as uuidv4 } from "uuid";

import Grid from "../../../../../components/Grid";
import { DeliveryAddressModal } from "./DeliveryAddressesModal";
import { Button, Tooltip } from "reactstrap";

const applyFilter = (address, filter) => {
  const { columnName, value, operation } = filter;

  switch (columnName) {
    case "address":
      if (operation === "contains") {
        return address.address.includes(value);
      }
      break;

    case "isMain":
      if (operation === "equal") {
        return address.isMain === (value === "true");
      }
      break;

    case "description":
      if (operation === "contains") {
        return address.description.includes(value);
      }
      break;

    default:
      return true;
  }

  return false;
};

export const DeliveryAddresses = ({
  values,
  setFieldValue,
  isIntegratedWithASW,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDeliveryAddress, setCurrentDeliveryAddress] = useState(null);
  const [filters, setFilers] = useState([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [clearSelection, setClearSelection] = useState(false);

  const isMainRowSelectedWithoutAllAddresses =
    selected.some((row) => row?.isMain) &&
    values.eDeliveryAddresses?.length > selected?.length;

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const filteredAddresses = () => {
    if (!filters.length) return values.eDeliveryAddresses || [];

    return values.eDeliveryAddresses.filter((address) =>
      filters.every((filter) => applyFilter(address, filter))
    );
  };

  useEffect(() => {
    if (clearSelection) {
      setSelected([]);
      setClearSelection(false);
    }
  }, [clearSelection]);

  return (
    <div>
      <FieldArray
        name="eDeliveryAddresses"
        key={"fieldId"}
        render={(arrayHelpers) => {
          const onSubmit = (modalValues) => {
            if (currentDeliveryAddress) {
              const index = (values?.eDeliveryAddresses || []).findIndex(
                (item) => {
                  return item.id === currentDeliveryAddress.id;
                }
              );

              arrayHelpers.replace(index, {
                ...modalValues,
                id: currentDeliveryAddress.id,
              });
            } else {
              arrayHelpers.push({
                ...modalValues,
                id: modalValues.id || uuidv4(),
              });
            }

            toggle();
          };

          const changeMainAddress = () => {
            const currentAddresses = values.eDeliveryAddresses.map(
              (address) => {
                if (address.id === selected?.[0].id) {
                  return {
                    ...address,
                    isMain: true,
                  };
                }

                return {
                  ...address,
                  isMain: false,
                };
              }
            );

            setFieldValue("eDeliveryAddresses", currentAddresses);
            setClearSelection(true);
          };

          return (
            <>
              <Grid
                items={filteredAddresses()}
                totalItems={filteredAddresses().length}
                columns={[
                  { name: "address", title: "Adres" },
                  { name: "isMain", title: "Główny" },
                  { name: "description", title: "Opis" },
                ]}
                boolColumns={["isMain"]}
                simpleInputColumns={["address", "description"]}
                refreshable={false}
                pagination={false}
                onAdd={() => {
                  setCurrentDeliveryAddress(null);
                  toggle();
                }}
                onRemove={(ids) => {
                  const indexes = ids
                    .map((id) =>
                      values.eDeliveryAddresses.findIndex(
                        (item) => item.id === id
                      )
                    )
                    .filter((index) => index !== -1);

                  indexes.sort((a, b) => b - a);

                  indexes.forEach((index) => {
                    arrayHelpers.remove(index);
                  });
                }}
                onRowClick={(row) => {
                  if (isIntegratedWithASW) return;

                  setCurrentDeliveryAddress(row);
                  toggle();
                }}
                onChangeFilters={(filters) => {
                  setFilers(filters);
                }}
                filters={filters}
                onClearFilters={() => {
                  setFilers([]);
                }}
                isDisableAdd={
                  values?.eDeliveryAddresses?.length >= 5 || isIntegratedWithASW
                }
                addTooltipMessage={
                  isIntegratedWithASW
                    ? "Ze względu na integrację, dodawanie ADE jest możliwe wyłącznie po stronie ASW."
                    : values?.eDeliveryAddresses?.length >= 5
                    ? "Maksymalna ilość adresów wynosi 5"
                    : ""
                }
                isDisableRemove={
                  isMainRowSelectedWithoutAllAddresses || isIntegratedWithASW
                }
                removeTooltipMessage={
                  isIntegratedWithASW
                    ? "Ze względu na integrację, usuwanie ADE jest możliwe wyłącznie po stronie ASW."
                    : !selected.length
                    ? "Wybierz adres do usunięcia"
                    : isMainRowSelectedWithoutAllAddresses
                    ? "Aby usunąć adres główny, zaznacz wszystkie istniejące adresy"
                    : ""
                }
                isClearSelection={clearSelection}
                rightButtons={[
                  <>
                    <Button
                      color="primary"
                      className="mb-2 mr-2 btn-icon"
                      id="main-btn"
                      disabled={
                        selected.length !== 1 ||
                        selected?.[0].isMain ||
                        isIntegratedWithASW
                      }
                      onClick={() => changeMainAddress()}
                    >
                      Ustaw jako główny
                    </Button>
                    {selected.length !== 1 || selected?.[0].isMain ? (
                      <Tooltip
                        {...{
                          placement: "top",
                          isOpen: isTooltipOpen,
                          toggle: () => setIsTooltipOpen((prev) => !prev),
                          target: "main-btn",
                        }}
                      >
                        {isIntegratedWithASW
                          ? "Ze względu na integrację, zmiana adresu głównego jest możliwa wyłącznie po stronie ASW."
                          : selected.length !== 1
                          ? "Wybierz jeden adres"
                          : selected?.[0].isMain && selected.length === 1
                          ? "Adres jest już ustawiony jako główny"
                          : ""}
                      </Tooltip>
                    ) : null}
                  </>,
                ]}
                onSelectionChange={(selected) => {
                  setSelected(selected);
                }}
              />
              <DeliveryAddressModal
                {...{
                  isOpen,
                  toggle,
                  currentDeliveryAddress,
                  onSubmit,
                  deliveryAddresses: values?.eDeliveryAddresses || [],
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
};
