import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import InputMask from "react-input-mask";

export const generateSchema = ({ fields }) => {
  return yup.object().shape({
    address: yup
      .string()
      .test(
        "unique-address",
        "Podany adres już istnieje na liście",
        function (val) {
          const otherFIelds = fields.filter(
            (field) => field.id !== this.parent.id
          );

          return otherFIelds.every((field) => field.address !== val);
        }
      )
      .required("Pole wymagane"),
    description: yup.string().max(200, "Maksymalnie 200 znaków"),
  });
};

export const DeliveryAddressModal = ({
  isOpen,
  toggle,
  currentDeliveryAddress,
  deliveryAddresses,
  onSubmit,
}) => {
  const initialValues = {
    address: "",
    isMain: deliveryAddresses?.length === 0,
    description: "",
  };

  const schema = generateSchema({ fields: deliveryAddresses });

  return (
    <Modal {...{ isOpen, size: "xl" }}>
      <Formik
        {...{
          initialValues: currentDeliveryAddress || initialValues,
          onSubmit,
          validationSchema: schema,
        }}
      >
        {({ values, errors, handleSubmit, handleBlur, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader>Adres e-doręczeń</ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="address">Adres *</Label>
                    <Input
                      id="address"
                      value={values.address}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(
                          /([a-z]+)/g,
                          (match) => match.toUpperCase()
                        );
                        handleChange({
                          target: {
                            name: "address",
                            value: newValue,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      invalid={!!errors.address}
                      tag={InputMask}
                      mask="AE:PL-99999-99999-aaaaa-99"
                      placeholder={"Wprowadź adres e-Doręczeń"}
                    />
                    <FormFeedback>{errors.address}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={"form-check"}>
                    <Label check>
                      <Input
                        id="isMain"
                        type="checkbox"
                        value={values.isMain}
                        checked={values.isMain}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.isMain}
                        disabled={true}
                      />
                      Główny
                    </Label>
                    <FormFeedback>{errors.isMain}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="description">Opis</Label>
                    <Input
                      id="description"
                      type="textarea"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.description}
                      rows={12}
                      placeholder={"Wprowadź opis adresu e-Doręczeń"}
                    />
                    <FormFeedback>{errors.description}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                {...{
                  type: "submit",
                  color: "primary",
                }}
              >
                Zapisz
              </Button>
              <Button
                {...{
                  type: "button",
                  color: "secondary",
                  onClick: toggle,
                }}
              >
                Anuluj
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
