import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import ReactCrop from "react-image-crop";

import {
  canvasPreview,
  centerAspectCrop,
  useDebounceEffect,
} from "./CropImageHelpers";

const rectangleSize = 230;
const defaultStyle = {
  minHeight: "230px",
  minWidth: "230px",
  maxHeight: "400px",
  maxWidth: "400px",
  backgroundColor: "grey",
};

const defaultPreviewStyle = {
  height: "230px",
  width: "230px",
  backgroundColor: "grey",
};

export const CropImageUpload = ({
  path = "",
  fileName = "",
  cropData = {
    unit: "px",
    x: 0,
    y: 0,
    width: rectangleSize,
    height: rectangleSize,
  },
  previewSize = {
    width: rectangleSize,
    height: rectangleSize,
  },
  normalStyle = defaultStyle,
  previewStyle = defaultPreviewStyle,
  keepSelection = true,
  locked = true,
  minHeight = previewSize.height,
  maxHeight = previewSize.height,
  minWidth = previewSize.width,
  maxWidth = previewSize.width,
  onCroppedImage = (cropData) => {},
  onRemoveImage = () => {},
}) => {
  const [imgSrc, setImgSrc] = useState(path ? path : "");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(
    cropData
      ? cropData
      : {
          unit: "px",
          x: 0,
          y: 0,
          width: rectangleSize,
          height: rectangleSize,
        }
  );
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(!locked ? undefined : 1);

  useDebounceEffect(
    () => {
      if (
        completedCrop &&
        completedCrop.width &&
        completedCrop.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onImageLoad = (e) => {
    if (aspect && !crop) {
      let { width, height } = e.currentTarget;
      height = height < maxHeight ? maxHeight : height;
      width = width < maxWidth ? maxWidth : width;
      setCrop(centerAspectCrop(width, height, aspect, maxWidth, maxHeight));
    }

    if (crop) {
      setCompletedCrop(crop);
    }
  };

  useEffect(() => {
    setImgSrc(path);
    setCrop(cropData);
  }, [path, cropData]);

  return (
    <Row>
      <Col style={{ margin: "10px 0" }}>
        <div className="d-flex he-100 justify-content-center align-items-end">
          <ReactCrop
            crop={crop}
            locked={locked}
            keepSelection={keepSelection}
            minHeight={minHeight}
            maxHeight={maxHeight}
            minWidth={minWidth}
            maxWidth={maxWidth}
            aspect={aspect}
            onChange={(crop, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => {
              setCompletedCrop(c);
              onCroppedImage(c);
            }}
          >
            <div style={normalStyle}>
              <img
                ref={imgRef}
                src={imgSrc}
                alt={`Grafika ${fileName}`}
                onLoad={onImageLoad}
              />
              <Button
                style={{
                  position: "absolute",
                  right: "0px",
                  bottom: "0px",
                  zIndex: 2,
                }}
                size="sm"
                color="danger"
                onClick={(e) => onRemoveImage()}
                title="Usuwa grafikę"
              >
                <i className="pe-7s-trash btn-icon-wrapper" />
              </Button>
            </div>
          </ReactCrop>
        </div>
      </Col>
      {completedCrop && (
        <Col style={{ margin: "10px 0" }}>
          <div className="d-flex he-100 justify-content-center align-items-end">
            <div style={previewStyle}>
              <canvas
                ref={previewCanvasRef}
                style={{
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};
