import React from "react";
import map from "lodash.map";
import filter from "lodash.filter";

import Grid from "../../../../components/Grid";
import { filterCell } from "./UserListHelpers";

const columns = [
  { name: "id", title: "id" },
  { name: "userName", title: "Login" },
  { name: "email", title: "Adres e-mail" },
  { name: "firstname", title: "Imię" },
  { name: "surname", title: "Nazwisko" },
  { name: "pesel", title: "PESEL" },
  { name: "isActive", title: "Aktywny" },
];
const columnExtensions = [
  {},
  {
    columnName: "surname",
    wordWrapEnabled: true,
    width: "20%",
  },
  {
    columnName: "isActive",
    width: "10%",
    align: "center",
  },
];
const hiddenColumns = ["id"];
const textColumns = ["email", "firstname", "surname", "pesel"];
const boolColumns = ["isActive"];
const boolFilterOperations = ["equal", "notEqual"];
const starColumns = ["userName"];
const currentPage = 0;

export const UserList = ({ ...props }) => (
  <Grid
    {...props}
    items={props.items}
    totalItems={props.totalItems}
    columns={columns}
    columnExtensions={columnExtensions}
    hiddenColumns={hiddenColumns}
    textColumns={textColumns}
    boolColumns={boolColumns}
    starColumns={starColumns}
    boolFilterOperations={boolFilterOperations}
    currentPage={currentPage}
    filterCell={filterCell}
    onReload={props.onReload}
    onRemove={props.onRemove}
    onAdd={props.onAdd}
    onRowClick={props.onRowClick}
    excludeItemIdFromSelection={map(
      filter(props.items, (item) => !item.isActive),
      (item) => item.id
    )}
    internationalizable={props.internationalizable}
    language={props.language}
    languages={props.languages}
    onChangeLanguage={props.onChangeLanguage}
    removeable={false}
    filters={props.filters}
    onChangeFilters={props.onChangeFilters}
    onClearFilters={props.onClearFilters}
  />
);
