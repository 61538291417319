import * as yup from "yup";
import { ArticleStatus, ArticleType } from "../helpers/enums";
import filter from "lodash.filter";
import isEmpty from "lodash.isempty";

export const ArticleValidationSchema = yup.object().shape({
  title: yup.string().trim().required("Tytuł artykułu jest polem wymaganym"),
  shortDescription: yup
    .string()
    .trim()
    .max(
      200,
      "Maksymalna długość skróconego opisu nie może przekroczyć 200 znaków"
    )
    .required("Skrócony opis artykułu jest polem wymaganym"),
  status: yup
    .number()
    .oneOf([
      ArticleStatus.Draft.value,
      ArticleStatus.Confirmed.value,
      ArticleStatus.ToPublish.value,
      ArticleStatus.Published.value,
      ArticleStatus.Withdrawed.value,
      ArticleStatus.Archived.value,
    ]),
  publishStart: yup.date().when(["status"], {
    is: (status) => status < ArticleStatus.ToPublish.value,
    then: yup
      .date()
      .nullable()
      .min(new Date(), "Data publikacji od musi być większa od daty aktualnej"),
    otherwise: yup.date().nullable(),
  }),
  publishEnd: yup
    .date()
    .when(["archiveAfterPublishEnd"], {
      is: (archiveAfterPublishEnd) => archiveAfterPublishEnd,
      then: yup.date().required("Data publikacji do jest polem wymaganym"),
      otherwise: yup.date().nullable(),
    })
    .test(
      "Publish end must be greater than publish start",
      "Data publikacji do musi być późniejsza niż data publikacji od",
      function (publishEnd) {
        if (this.parent.publishStart && publishEnd) {
          return publishEnd > this.parent.publishStart;
        }
        return true;
      }
    )
    .test(
      "Publish end must be greater than",
      "Data publikacji do musi być późniejsza niż aktualna",
      function (publishEnd) {
        if (publishEnd) {
          return publishEnd >= new Date();
        }
        return true;
      }
    ),
  categories: yup
    .array()
    .when(
      ["translations", "baseArticleType"],
      function (translations, baseArticleType) {
        const filtered = filter(
          translations,
          (translation) => translation.status === ArticleStatus.Published.value
        );
        if (
          !isEmpty(filtered) &&
          baseArticleType === ArticleType.Common.value
        ) {
          return yup
            .array()
            .min(
              1,
              "Artykuł zawiera opublikowaną wersję językową i musi posiadać przynajmniej jedną kategorię"
            );
        }
        return yup.array();
      }
    ),
  translations: yup.array(),
  archiveAfterPublishEnd: yup.bool(),
  baseArticleType: yup.number(),
  baseArticleProvidingEntity: yup
    .string()
    .trim()
    .required("Podmiot udostępniający jest polem wymaganym")
    .max(250, "Maksymalna długość podmiotu nie może przekroczyć 250 znaków"),
  baseArticleAuthorName: yup
    .string()
    .trim()
    .required("Autor informacji jest polem wymaganym"),
  baseArticleAuthorEmail: yup
    .string()
    .trim()
    .email("Wartość w polu E-mail nie jest prawidłowym formatem adresu e-mail"),
  baseArticleInformationCreated: yup
    .date()
    .required("Data powstania informacji źródłowej jest polem wymaganym")
    .test(
      "prevent later than actual",
      "Data powstania informacji źródłowej nie może być większa od aktualnej",
      function (date) {
        const actualDate = new Date();

        return date <= actualDate;
      }
    ),
});
