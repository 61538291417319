import React, { Component, Fragment } from "react";
import Dropzone from "react-dropzone";
import { Button, Progress } from "reactstrap";

import axios from "axios";
import { toast } from "react-toastify";
import isEmpty from "lodash.isempty";

import { SizeUnit, UPLOAD_URL, FILE_PARAM_NAME } from "../helpers/enums";

export default class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.attachmentDropzone = null;
    this.state = {
      progressUpload: 0,
      uploading: false,
    };
  }

  uploadFile = async (files) => {
    const {
      multiple = false,
      onDropAccepted,
      recommendedWidth = null,
      recommendedHeight = null,
      isIsoCode,
      uploadUrl = UPLOAD_URL,
      fileParamName = FILE_PARAM_NAME,
    } = this.props;
    this.setState({ progressUpload: 0, uploading: true });
    const self = this;
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted === 100) {
          self.setState({ progressUpload: percentCompleted - 1 });
        } else {
          self.setState({ progressUpload: percentCompleted });
        }
      },
    };

    const formData = new FormData();
    if (!multiple) {
      const file = files[0];

      let isoCodeFileHelper = isIsoCode ? Object.assign({}, files) : null;
      if (isoCodeFileHelper) {
        formData.append(fileParamName, isoCodeFileHelper[0]);
      } else {
        formData.append(fileParamName, file);
      }
      if (recommendedHeight) {
        formData.append("recommendedHeight", recommendedHeight);
      }
      if (recommendedWidth) {
        formData.append("recommendedWidth", recommendedWidth);
      }
    } else {
      files.forEach((file) => {
        formData.append(fileParamName, file);
        if (recommendedHeight) {
          formData.append("recommendedHeight", recommendedHeight);
        }
        if (recommendedWidth) {
          formData.append("recommendedWidth", recommendedWidth);
        }
      });
    }
    try {
      const response = await axios.post(`${uploadUrl}`, formData, config);
      const uploadedFiles = response.data;
      if (!isEmpty(uploadedFiles) && multiple) {
        uploadedFiles.forEach((uploadedFile) => onDropAccepted(uploadedFile));
      } else if (!isEmpty(uploadedFiles) && !multiple) {
        onDropAccepted(uploadedFiles[0]);
      } else {
        onDropAccepted();
      }
    } catch (e) {
      console.error(e);
      toast.error("Wystąpił niespodziewany błąd podczas uploadu pliku");
      return -1;
    } finally {
      self.setState({ progressUpload: 0, uploading: false });
    }
  };

  render() {
    const {
      disabled = false,
      maxFileSize = 10,
      acceptFileType,
      additionalInfo = "width: 50px",
      multiple = false,
      acceptExtensionFiles,
      onDrop,
      onDropRejected,
      onCancel,
    } = this.props;

    const { progressUpload, uploading } = this.state;

    return (
      <Fragment>
        <div className="dropzone-wrapper dropzone-wrapper-sm mb-2">
          <Dropzone
            ref={(ref) => (this.attachmentDropzone = ref)}
            onDrop={onDrop}
            onDropAccepted={this.uploadFile}
            onDropRejected={onDropRejected}
            onFileDialogCancel={onCancel}
            multiple={multiple}
            disabled={disabled || uploading}
            acceptedFiles={acceptFileType}
            accept={acceptExtensionFiles}
            maxSize={maxFileSize * SizeUnit.MB}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-content">
                  <div className="dropzone-content__text">
                    Przeciągnij tutaj plik <br />
                    lub
                  </div>
                  <div className="dropzone-content__button mb-3">
                    <Button color="primary" disabled={disabled}>
                      Wybierz plik
                    </Button>
                  </div>

                  <small>{additionalInfo}</small>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        {progressUpload > 0 && (
          <Progress className="mb-3" animated value={progressUpload} />
        )}
      </Fragment>
    );
  }
}
UploadFile.defaultProps = {
  disabled: false,
  maxFileSize: 10 * SizeUnit.MB,
  acceptFileType: "*",
  additionalInfo: "",
  uploadUrl: UPLOAD_URL,
  fileParamName: FILE_PARAM_NAME,
  acceptExtensionFiles: "",
  onDrop: Function.prototype,
  onDropAccepted: Function.prototype,
  onDropRejected: Function.prototype,
  onCancel: Function.prototype,
};
