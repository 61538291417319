import React, { Fragment } from "react";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import { createUserManager } from "redux-oidc";
import { OidcConfiguration } from "../../helpers/ocid";
import SweetAlert from "react-bootstrap-sweetalert";
import { WARNING_SESSION_TIMEOUT } from "../../helpers/units";
import isEqual from "lodash.isequal";

import { currentBuildVersion } from "../../version";

let remainingInterval = null;
const EMPTY_EVENTS = [];
const DEFAULT_EVENTS = ["click"];

class AppFooter extends React.Component {
  observer = null;
  checkExistInterval = null;

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      timeout: props.sessionTimeout,
      remaining: props.sessionTimeout,
      sessionEnabled: props.sessionEnabled,
      warningSessionEndModal: false,
      events: DEFAULT_EVENTS,
    };

    this.handleOnIdle = this.handleOnIdle.bind(this);

    document.addEventListener("keydown", this.handleUserActivity);
    document.addEventListener("click", this.handleUserActivity);
    document.addEventListener("scroll", this.handleUserActivity);
  }

  componentDidMount() {
    const { sessionEnabled } = this.state;
    if (sessionEnabled) {
      this._endInterval();
      this._startInterval();
      this.initializeMutationObserver();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleUserActivity);
    document.removeEventListener("click", this.handleUserActivity);
    document.removeEventListener("scroll", this.handleUserActivity);

    if (this.checkExistInterval) {
      clearInterval(this.checkExistInterval);
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { sessionEnabled } = this.state;
    if (sessionEnabled) {
      if (prevProps.lastResetSessionTime !== this.props.lastResetSessionTime) {
        this.idleTimer.reset();
      }
      if (prevProps.sessionTimeout !== this.props.sessionTimeout) {
        this.setState({ timeout: this.props.sessionTimeout });
      }
    }
    if (
      prevProps.sessionEnabled !== this.props.sessionEnabled &&
      this.props.sessionEnabled
    ) {
      this.setState({
        timeout: this.props.sessionTimeout,
        sessionEnabled: this.props.sessionEnabled,
      });
      this._endInterval();
      this._startInterval();
    }

    if (
      prevProps.sessionEnabled !== this.props.sessionEnabled &&
      !this.props.sessionEnabled
    ) {
      this.setState({ sessionEnabled: this.props.sessionEnabled });
      this._endInterval();
    }

    if (
      !isEqual(prevState.events, this.state.events) &&
      this.state.events.length === 0
    ) {
      this.idleTimer._unbindEvents(true);
    }

    if (
      !isEqual(prevState.events, this.state.events) &&
      this.state.events.length !== 0
    ) {
      this.idleTimer._bindEvents();
    }
  }

  handleUserActivity = () => {
    if (this.idleTimer) {
      this.idleTimer.reset();
    }
  };

  initializeMutationObserver = () => {
    this.checkExistInterval = setInterval(() => {
      const iframe = document.querySelector('iframe[id^="tiny-react"]');
      if (iframe) {
        clearInterval(this.checkExistInterval);
        this.observeIframe(iframe);
      }
    }, 100);
  };

  observeIframe = (iframe) => {
    const config = { childList: true, subtree: true, characterData: true };
    this.observer = new MutationObserver((mutationsList, observer) => {
      if (this.idleTimer) {
        this.idleTimer.reset();
      }
    });

    if (iframe.contentDocument || iframe.contentWindow.document) {
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      this.observer.observe(doc.body, config);
    } else {
      iframe.addEventListener("load", () => {
        const doc = iframe.contentDocument || iframe.contentWindow.document;
        this.observer.observe(doc.body, config);
      });
    }
  };

  _endInterval = () => {
    if (remainingInterval) {
      clearInterval(remainingInterval);
    }
  };

  _startInterval = () => {
    remainingInterval = setInterval(() => {
      const isWarningSessionEndModal = !this.state.warningSessionEndModal
        ? (this.idleTimer ? this.idleTimer.getRemainingTime() : this.timeout) <
          WARNING_SESSION_TIMEOUT
        : true;
      this.setState({
        remaining: this.idleTimer
          ? this.idleTimer.getRemainingTime()
          : this.timeout,
        warningSessionEndModal: isWarningSessionEndModal,
        events: isWarningSessionEndModal ? EMPTY_EVENTS : DEFAULT_EVENTS,
      });
    }, 1000);
  };

  async handleOnIdle() {
    const { serverIdentity } = this.props;
    const { sessionEnabled } = this.state;
    if (sessionEnabled) {
      const userManager = createUserManager({
        ...OidcConfiguration,
        ...serverIdentity,
        response_type: "code",
        scope: `openid profile offline_access ${serverIdentity.scope}`,
      });
      await userManager.signoutRedirect();
    }
  }

  millisecondsToHuman = (ms) => {
    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / 1000 / 60) % 60);
    // let hours = Math.floor((ms / 1000 / 3600) % 24);

    // hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
  };

  closeWarningSessionEndModal = () => {
    this.idleTimer.reset();
    this.setState({
      events: DEFAULT_EVENTS,
      warningSessionEndModal: false,
    });
  };

  render() {
    const { appVersion } = this.props;
    const { remaining, timeout, sessionEnabled, warningSessionEndModal } =
      this.state;
    return (
      <Fragment>
        <SweetAlert
          show={warningSessionEndModal}
          warning
          showCancel={false}
          confirmBtnText="Przedłuż"
          confirmBtnBsStyle="success"
          title="Twoja sesja wkrótce wygaśnie"
          onConfirm={this.closeWarningSessionEndModal}
          closeOnClickOutside={false}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "-15%",
            marginBottom: "-15%",
            position: "fixed",
          }}
        >
          <Fragment>
            <h2 className="fsize-3 fweight-6">
              Pozostało:{" "}
              <span className="fsize-4">
                <strong>{this.millisecondsToHuman(remaining)}</strong>
              </span>
            </h2>
          </Fragment>
        </SweetAlert>
        {sessionEnabled && (
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            events={DEFAULT_EVENTS}
            onIdle={this.handleOnIdle}
            timeout={timeout}
          />
        )}
        <div id="app-footer" className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-right flex-column align-items-end">
              {sessionEnabled && (
                <div className="widget-subheading opacity-8">
                  sesja wygaśnie za: {this.millisecondsToHuman(remaining)}
                </div>
              )}
              <div className="widget-subheading opacity-8">
                wersja: {appVersion} ({currentBuildVersion})
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  appVersion: state.application.version,
  sessionTimeout: state.application.sessionTimeout,
  sessionEnabled: state.application.sessionEnabled,
  lastResetSessionTime: state.application.lastResetSessionTime,
  oidcUser: state.oidc.user || {
    profile: { name: "", sub: "" },
  },
  serverIdentity: state.application.serverIdentity,
});

export default connect(mapStateToProps)(AppFooter);
