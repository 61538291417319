import React, { Component, Fragment } from "react";
import { Button, Input, ListGroup, ListGroupItem } from "reactstrap";
import { faDownload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import map from "lodash.map";
import isEmpty from "lodash.isempty";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { useDownloadData } from "../../hooks/use-download-data";

const ListHeader = ({ columns }) => (
  <ListGroup className="todo-list-wrapper" flush>
    <ListGroupItem>
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left d-flex width-100">
            {map(columns, (item) => (
              <div key={item.name} className={item.class}>
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </ListGroupItem>
  </ListGroup>
);

const ListItem = SortableElement(
  ({
    item = { filename: "", displayName: "" },
    disabled,
    index,
    prefixName,
    removeable,
    onRemoveItem,
    onChangeItem,
    value,
    addedAttachments,
    publishedArticle,
  }) => {
    const [download] = useDownloadData({
      baseUrl: `api/files/attachment/${item.documentRepositoryId}`,
      fileName: item.filename,
    });

    return (
      <ListGroupItem>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left d-flex width-100">
              <div className="widget-subheading flex-basis-50">
                {item.filename}
              </div>
              <div className="widget-subheading flex-basis-50">
                <div className="pr-2">
                  <Input
                    type="text"
                    id={`${prefixName}.displayName`}
                    name={`${prefixName}.displayName`}
                    value={value}
                    onChange={onChangeItem}
                    disabled={
                      publishedArticle && !addedAttachments
                        ? !!item.id
                        : disabled
                    }
                  />
                </div>
              </div>
              <div className="widget-subheading d-flex flex-basis-10">
                <div className="widget-content-right">
                  {removeable && (
                    <Button
                      className="border-0 btn-transition"
                      outline
                      color="danger"
                      onClick={() => onRemoveItem(index)}
                      disabled={
                        publishedArticle && !addedAttachments
                          ? !!item.id
                          : disabled
                      }
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  )}
                </div>
                <div className="widget-content-right">
                  {removeable && (
                    <Button
                      className="border-0 btn-transition"
                      outline
                      color="info"
                      onClick={() => download()}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  }
);

const ListSortableContainer = SortableContainer(
  ({
    items,
    disabled,
    removeable,
    prefixName,
    grabbing = false,
    onRemoveItem,
    onChangeItem,
    addedAttachments,
    publishedArticle,
  }) => (
    <ListGroup
      className="todo-list-wrapper"
      style={{ cursor: `${grabbing ? "grabbing" : "grab"}` }}
      flush
    >
      {map(items, (item, index) => (
        <ListItem
          {...{
            key: `attachment-${item.documentRepositoryId}`,
            prefixName: `${prefixName}.${index}`,
            value: item ? item.displayName : "",
            disabled,
            item,
            index,
            removeable,
            onRemoveItem,
            onChangeItem,
            addedAttachments,
            publishedArticle,
          }}
        />
      ))}
    </ListGroup>
  )
);

class FileListGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      grabbing: false,
    };
  }

  toggleModal = () => {
    this.setState((state) => ({ modalIsOpen: !state.modalIsOpen }));
  };

  onSortEnd = ({ newIndex, oldIndex }) => {
    const { setFieldValue, items } = this.props;

    this.setState({ ...this.state, grabbing: false });
    const newItems = arrayMove(items, oldIndex, newIndex);

    setFieldValue("attachments", newItems);
  };

  onGrabbing = () => this.setState({ ...this.state, grabbing: true });

  render() {
    const {
      items,
      columns = [],
      removeable,
      prefixName,
      onRemoveItem,
      onChangeItem,
      addedAttachments,
      publishedArticle,
      disabled,
    } = this.props;
    const { grabbing } = this.state;

    return (
      <Fragment>
        {!isEmpty(items) && <ListHeader columns={columns} />}
        <ListSortableContainer
          helperClass="sortableHelper"
          {...{
            items,
            removeable,
            prefixName,
            grabbing,
            onRemoveItem,
            onChangeItem,
            pressDelay: 50,
            onSortEnd: this.onSortEnd,
            onSortStart: this.onGrabbing,
            disabled,
            addedAttachments: addedAttachments,
            publishedArticle: publishedArticle,
          }}
        />
      </Fragment>
    );
  }
}

export default FileListGroup;
