import React from "react";
import RSC from "react-scrollbars-custom";
import axios from "axios";
import { Loader } from "react-loaders";
import BlockUi from "react-block-ui";
import { toast } from "react-toastify";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  UncontrolledButtonDropdown,
  Button,
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoaderType } from "../../../helpers/enums";

export const HelpPage = ({ knownledgeBaseEntries }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchLink = (id) => {
    setIsLoading(true);

    axios
      .get(`/api/application/knownledge-base/${id}`)
      .then((response) => {
        const url = response.data;

        window.open(url, "_blank");
      })
      .catch(() => {
        toast.error("Wystąpił błąd podczas pobierania danych");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <i className="pe-7s-help1" style={{ fontSize: "42px" }} />
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <BlockUi
                    tag="div"
                    blocking={isLoading}
                    loader={<Loader active type={LoaderType} />}
                  >
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div className="menu-header-image opacity-2" />
                        <div className="menu-header-content text-left">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <i
                                  className="pe-7s-help1"
                                  style={{ fontSize: "42px" }}
                                />
                              </div>
                              <div
                                className="widget-content-left"
                                style={{ width: "185px" }}
                              >
                                <div
                                  className="widget-heading"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  Baza wiedzy
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="scroll-area-xs"
                      style={{
                        height: "170px",
                      }}
                    >
                      <RSC>
                        <Nav vertical>
                          {knownledgeBaseEntries.map((item) => (
                            <NavItem>
                              <Button
                                color="link"
                                onClick={() => {
                                  fetchLink(item.id);
                                }}
                              >
                                {item.displayName}
                              </Button>
                            </NavItem>
                          ))}
                        </Nav>
                      </RSC>
                    </div>
                  </BlockUi>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
