import React, { useState, useRef, useEffect } from "react";
import { useCallback } from "react";
import ReactCrop from "react-image-crop";
import { Button, Col, Row } from "reactstrap";

export const useDebounceEffect = (fn, waitTime, deps) => {
  useEffect(() => {
    const t = setTimeout(() => fn.apply(undefined, deps), waitTime);

    return () => {
      clearTimeout(t);
    };
  }, [deps, fn, waitTime]);
};

export const CropUpload = ({
  imgSrc,
  originalSrc,
  fileName,
  cropData,
  rectangleSize = { width: 230, height: 230 },
  onRemoveImage,
  onCroppedImage,
  locked = true,
  keepRectangleHeight = false,
  maxCropWidth = 0,
}) => {
  const [originalImageSize, setOriginalImageSize] = useState({
    width: 0,
    height: 0,
  });
  const [scale, setScale] = useState(1);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: rectangleSize.width,
    height: rectangleSize.height,
  });
  const [completedCrop, setCompletedCrop] = useState();
  const [isBasedOnImageWidth, setIsBasedOnImageWidth] = useState(false);
  const [isBasedOnImageHeight, setIsBasedOnImageHeight] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  const imgRef = useRef();
  const wrapperRef = useRef();
  const cropWrapperRef = useRef();
  const previewCanvasRef = useRef(null);

  const updateWrapperDimensions = useCallback(() => {
    if (wrapperRef.current && cropWrapperRef.current) {
      const wrapperWidth = wrapperRef.current.clientWidth;
      const currentScale = isBasedOnImageWidth
        ? wrapperWidth / originalImageSize.width
        : wrapperWidth / rectangleSize.width;

      setScale(currentScale);
    }
  }, [isBasedOnImageWidth, originalImageSize.width, rectangleSize.width]);

  const generateCroppedImage = (crop) => {
    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const image = imgRef.current;

    const scaleX = originalImageSize.width / image.width;
    const scaleY = originalImageSize.height / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    ctx.restore();
  };

  const onImageLoad = (e) => {
    if (crop) {
      setCompletedCrop(crop);
    }
  };

  useEffect(() => {
    const updateAfterRender = () => {
      setTimeout(() => {
        updateWrapperDimensions();
      }, 0);
    };

    updateAfterRender();

    const handleResize = () => {
      updateWrapperDimensions();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [completedCrop, crop, scale, updateWrapperDimensions]);

  useEffect(() => {
    setCrop(cropData);
  }, [cropData]);

  useEffect(() => {
    if (originalSrc) {
      const img = new Image();
      img.src = originalSrc;

      img.onload = () => {
        setOriginalImageSize({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });

        if (img.naturalWidth > rectangleSize.width) {
          setIsBasedOnImageWidth(true);
        }

        if (img.naturalHeight > rectangleSize.height) {
          setIsBasedOnImageHeight(true);
        }
      };
    }
  }, [originalSrc, rectangleSize.height, rectangleSize.width]);

  useDebounceEffect(
    () => {
      if (completedCrop && !isMounted) {
        generateCroppedImage({
          ...completedCrop,
          x: completedCrop?.x * scale,
          y: completedCrop?.y * scale,
          width: completedCrop?.width * scale,
          height: completedCrop?.height * scale,
        });
      }
    },
    100,
    [completedCrop, scale, isMounted]
  );

  return (
    <>
      <Row>
        <Col>
          <div
            className="d-flex he-100 justify-content-center align-items-end"
            ref={cropWrapperRef}
          >
            <ReactCrop
              {...{
                crop: {
                  unit: "px",
                  x: crop.x * scale,
                  y: crop.y * scale,
                  width: crop.width * scale,
                  height: keepRectangleHeight
                    ? rectangleSize.height * scale
                    : crop.height * scale,
                },
                locked,
                // keepSelection: true,
                maxWidth: maxCropWidth * scale,
                onChange: (crop) => {
                  setCrop({
                    ...crop,
                    x: crop.x / scale,
                    y: crop.y / scale,
                    width: crop.width / scale,
                    height:
                      (keepRectangleHeight
                        ? rectangleSize.height
                        : crop.height) / scale,
                  });
                },

                onComplete: (crop) => {
                  const currentCrop = {
                    ...crop,
                    x: crop.x / scale,
                    y: crop.y / scale,
                    width: crop.width / scale,
                    height:
                      (keepRectangleHeight
                        ? rectangleSize.height
                        : crop.height) / scale,
                  };

                  generateCroppedImage(crop);
                  setIsMounted(true);

                  setCompletedCrop(crop);
                  onCroppedImage(currentCrop);
                },
              }}
            >
              <div
                style={{
                  background: "gray",
                  maxWidth: "100%",
                  width: !isBasedOnImageWidth ? rectangleSize.width : "100%",
                  height: !isBasedOnImageHeight
                    ? rectangleSize.height * scale
                    : "auto",
                }}
                ref={wrapperRef}
              >
                <img
                  src={originalSrc}
                  ref={imgRef}
                  alt={`Grafika ${fileName}`}
                  onLoad={onImageLoad}
                  style={{
                    maxWidth: "100%",
                    width: isBasedOnImageWidth
                      ? "100%"
                      : originalImageSize.width * scale,
                  }}
                />
                <Button
                  style={{
                    position: "absolute",
                    right: "0px",
                    bottom: "0px",
                    zIndex: 2,
                  }}
                  size="sm"
                  color="danger"
                  onClick={() => onRemoveImage()}
                  title="Usuń grafikę"
                >
                  <i className="pe-7s-trash btn-icon-wrapper" />
                </Button>
              </div>
            </ReactCrop>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {completedCrop ? (
            <div className="d-flex justify-content-center my-2">
              <canvas
                ref={previewCanvasRef}
                style={{
                  display: "block",
                  objectFit: "contain",
                  width: rectangleSize.width * scale,
                  height: rectangleSize.height * scale,
                }}
              />
            </div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
