import React from "react";
import { MenuConfigurationCard } from "./Cards/MenuConfigurationCard";
import { ConfigurationEditorType } from "../../../../helpers/enums";
import { ToggleCheckboxCard } from "./Cards/ToggleCheckboxCard";
import { InputCard } from "./Cards/InputCard";
import { NumberRange } from "./Cards/NumberRange";
import { ThemeColorPicker } from "./Cards/ThemeColorPicker";
import { SessionTimeout } from "./Cards/SessionTimeout";
import { TileModeCard } from "./Cards/TileModeCard";
import { IntegrationWithRadniInfo } from "./Cards/IntegrationWithRadniInfo";
import { CookieConfiguration } from "./Cards/CookieConfiguration";
import { Instruction } from "./Cards/Instruction";
import { GA } from "./Cards/GA";
import { UserManual } from "./Cards/UserManual";

export const ParameterEditorChooser = ({
  editorType,
  labelValue = "",
  value = "",
  displayName = "",
  onChange = () => {},
}) => {
  switch (editorType) {
    case ConfigurationEditorType.MenuConfiguration:
      return <MenuConfigurationCard {...value} title={displayName} />;
    case ConfigurationEditorType.ToggleCheckbox:
      return (
        <ToggleCheckboxCard
          {...value}
          title={displayName}
          labelValue={labelValue}
          onChange={onChange}
        />
      );
    case ConfigurationEditorType.Input:
      return <InputCard {...value} title={displayName} onChange={onChange} />;
    case ConfigurationEditorType.NumberRange:
      const range = { minValue: 0, value: 0, maxValue: 0, ...value };
      return (
        <NumberRange
          {...range}
          title={displayName}
          label={labelValue}
          onChange={onChange}
        />
      );
    case ConfigurationEditorType.ThemeColorPicker:
      const selected = { value: "standard", color: "#0077b3", ...value };
      return (
        <ThemeColorPicker
          selected={selected}
          colors={[
            { value: "standard", color: "#0077b3" },
            { value: "red", color: "#cd1040" },
            { value: "green", color: "#628033" },
            { value: "darkgreen", color: "#0c5944" },
          ]}
          title={displayName}
          label={labelValue}
          onChange={onChange}
        />
      );
    case ConfigurationEditorType.SessionTimeout:
      const defaultSessionTimeoutValue = {
        minValue: 0,
        value: 0,
        maxValue: 0,
        enabled: false,
        ...value,
      };
      return (
        <SessionTimeout
          {...defaultSessionTimeoutValue}
          title={displayName}
          label={labelValue}
          onChange={onChange}
        />
      );
    case ConfigurationEditorType.TileMode:
      const defaultTileModeValue = { value: 4, ...value };
      return (
        <TileModeCard
          {...defaultTileModeValue}
          title={displayName}
          label={labelValue}
          onChange={onChange}
        />
      );
    case ConfigurationEditorType.IntegrationWithRadniInfo:
      const defaultRadniInfoClientIdValue = { clientId: 0, ...value };
      return (
        <IntegrationWithRadniInfo
          {...defaultRadniInfoClientIdValue}
          title={displayName}
          label={labelValue}
          onChange={onChange}
        />
      );
    case ConfigurationEditorType.CookieConfiguration:
      const { description } = value;
      const defaultCookieConfigurationValue = {
        isEnabled: false,
        desc: description,
        ...value,
      };
      return (
        <CookieConfiguration
          {...defaultCookieConfigurationValue}
          title={displayName}
          label={labelValue}
          onChange={onChange}
        />
      );

    case ConfigurationEditorType.Instruction:
      return (
        <Instruction
          {...{
            editorType,
            labelValue,
            value,
            displayName,
            onChange,
          }}
        />
      );
    case ConfigurationEditorType.GA:
      return (
        <GA
          {...{
            value,
            displayName,
            onChange,
          }}
        />
      );
    case ConfigurationEditorType.UserManual:
      return (
        <UserManual
          {...{
            editorType,
            labelValue,
            value,
            displayName,
            onChange,
          }}
        />
      );
    default:
      return null;
  }
};
