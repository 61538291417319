import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

// import Ionicon from 'react-ionicons';

import RSC from "react-scrollbars-custom";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { toast, Bounce } from "react-toastify";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
// import avatar1 from '../../../assets/utils/images/avatars/1.jpg';

import { RouteUrls } from "../../../helpers/routeUrls";
import { createUserManager } from "redux-oidc";
import { OidcConfiguration } from "../../../helpers/ocid";

const JWT_USERNAME =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
class UserBox extends React.Component {
  notify2 = () =>
    (this.toastId = toast(
      "You don't have any new items in your calendar for today! Go out and play!",
      {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      }
    ));

  render() {
    const {
      isActive,
      isSystem,
      oidcUser,
      serverIdentity,
      isIntegratedWithASW,
    } = this.props;
    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    <i className="pe-7s-user" style={{ fontSize: "42px" }} />
                    <FontAwesomeIcon
                      className="ml-2 opacity-8"
                      icon={faAngleDown}
                    />
                  </DropdownToggle>
                  <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div className="menu-header-image opacity-2" />
                        <div className="menu-header-content text-left">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <i
                                  className="pe-7s-user"
                                  style={{ fontSize: "42px" }}
                                />
                              </div>
                              <div
                                className="widget-content-left"
                                style={{ width: "185px" }}
                              >
                                <div
                                  className="widget-heading"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {oidcUser.profile[JWT_USERNAME] ||
                                    oidcUser.profile.name}
                                </div>
                                <div className="widget-subheading opacity-8">
                                  {"Użytkownik"}
                                </div>
                              </div>
                              <div className="widget-content-right mr-2">
                                <Button
                                  className="btn-pill btn-shadow btn-shine"
                                  color="focus"
                                  onClick={async () => {
                                    const logoutManager = createUserManager({
                                      ...OidcConfiguration,
                                      ...serverIdentity,
                                      response_type: "code",
                                      scope: `openid profile offline_access ${serverIdentity.scope}`,
                                    });
                                    await logoutManager.signoutRedirect({
                                      id_token: oidcUser.id_token,
                                    });
                                  }}
                                >
                                  Wyloguj
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="scroll-area-xs"
                      style={{
                        height: "150px",
                      }}
                    >
                      <RSC>
                        <Nav vertical>
                          <NavItem className="nav-item-header">
                            Czynności
                          </NavItem>
                          <NavItem>
                            {isIntegratedWithASW && (
                              <a
                                className="nav-link"
                                href={`${serverIdentity.authority}/Account/ChangePassword?returnurl=${window.location.protocol}//${window.location.host}`}
                              >
                                Zmień hasło
                              </a>
                            )}
                            {!isIntegratedWithASW && (
                              <NavLink
                                className="nav-link"
                                to={RouteUrls.App.changePasswordFunc(
                                  oidcUser.profile.sub
                                )}
                              >
                                Zmień hasło
                              </NavLink>
                            )}
                          </NavItem>
                          {isActive && !isSystem && (
                            <NavItem>
                              <NavLink
                                className="nav-link"
                                to={RouteUrls.Auth.user.editFunc(
                                  oidcUser.profile.sub
                                )}
                              >
                                Profil
                              </NavLink>
                            </NavItem>
                          )}
                        </Nav>
                      </RSC>
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ml-3 header-user-info">
                <div className="widget-heading">
                  {oidcUser.profile[JWT_USERNAME] || oidcUser.profile.name}
                </div>
                <div className="widget-subheading">{"Użytkownik"}</div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  isActive: state.auth.isActive,
  isSystem: state.auth.isSystem,
  oidcUser: state.oidc.user || {
    profile: {
      name: "",
      sub: "",
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": "",
    },
  },
  serverIdentity: state.application.serverIdentity,
  isIntegratedWithASW: state.application.isIntegratedWithASW || false,
});

export default connect(mapStateToProps)(UserBox);
