import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";

// import map from "lodash.map";

import { Formik, FieldArray } from "formik";

import UploadFile from "@components/UploadFile";
import { ImageMimeType } from "@helpers/enums";
import { fileUploadError } from "@helpers/error-message";

import { CONSTANTS } from "@constants";
import {
  DefaultFormikConfig,
  FileExtensionTypes,
  ImageData,
} from "../../../../../helpers/enums";
import { CropUpload } from "../../../../../components/CropUpload";

const { CONFIRM, BACK, COMMON_INFORMATION } = CONSTANTS;

const RectangleSize = { width: 1240, height: 45 };
export default ({
  title = "Edycja grafiki",
  imageType = 0,
  maxFileSize = 10,
  isOpen = false,
  images = [],
  recommendedWidth = 256,
  recommendedHeight = 144,
  useImage = false,
  useGradient = false,
  updateImages = (images) => images,
  cancel = () => {},
}) => {
  const calculateUsingWidth = (images) =>
    !images
      ? RectangleSize.width
      : images.reduce(
          (sum, curr) => (curr.cropData ? sum + curr.cropData.width : 0),
          0
        );

  const [availableWidth, setAvailableWidth] = useState(
    RectangleSize.width - calculateUsingWidth(images)
  );

  useEffect(() => {
    const usingWidth = calculateUsingWidth(images);

    setAvailableWidth(RectangleSize.width - usingWidth);
  }, [images]);

  return (
    <Formik
      {...DefaultFormikConfig}
      initialValues={{ images }}
      onSubmit={(values) => {
        updateImages([...values.images]);
        cancel();
      }}
      onReset={() => {
        cancel();
      }}
    >
      {({ values, handleSubmit, handleReset }) => {
        return (
          <Modal
            isOpen={isOpen}
            backdrop
            size="xl"
            toggle={handleReset}
            style={{
              minWidth:
                imageType !== ImageData.euCollectionImages.id
                  ? "initial"
                  : "90%",
            }}
          >
            <ModalHeader>
              <div className="d-flex justify-content-between">
                <div>{title}</div>
                <div>
                  <Button
                    className="btn-icon mr-2"
                    color="alternate"
                    type="button"
                    onClick={handleSubmit}
                  >
                    <i className="pe-7s-check btn-icon-wrapper" />
                    {CONFIRM}
                  </Button>
                  <Button
                    className="btn-icon mr-2"
                    color="secondary"
                    type="button"
                    onClick={handleReset}
                  >
                    <i className="pe-7s-back btn-icon-wrapper" />
                    {BACK}
                  </Button>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <Card className="mb-2">
                <CardHeader>
                  <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                  {COMMON_INFORMATION}
                </CardHeader>
                <CardBody>
                  <FieldArray name="images">
                    {(arrayHelpers) => (
                      <Fragment>
                        <Col>
                          <UploadFile
                            disabled={availableWidth <= 0}
                            uploadUrl={"/api/files/crop"}
                            maxSize={maxFileSize}
                            multiple={true}
                            acceptFileType={`${ImageMimeType.BMP}, ${ImageMimeType.PNG}, ${ImageMimeType.JPG}, ${ImageMimeType.GIF}`}
                            acceptExtensionFiles={[
                              FileExtensionTypes.BMP,
                              FileExtensionTypes.PNG,
                              FileExtensionTypes.JPG,
                              FileExtensionTypes.JPEG,
                              FileExtensionTypes.GIF,
                            ]}
                            additionalInfo={`Zalecany format zdjęcia powinien być w formacie ${RectangleSize.width}x${RectangleSize.height} pikseli`}
                            recommendedWidth={RectangleSize.width}
                            recommendedHeight={RectangleSize.height}
                            onDrop={Function.prototype}
                            onDropAccepted={(file) => {
                              arrayHelpers.push({
                                ...file,
                                cropData: {
                                  unit: "px",
                                  x: 0,
                                  y: 0,
                                  width: availableWidth,
                                  height: RectangleSize.height,
                                },
                              });

                              const currentWidth = calculateUsingWidth(
                                values.images.concat({
                                  ...file,
                                  cropData: {
                                    unit: "px",
                                    x: 0,
                                    y: 0,
                                    width: availableWidth,
                                    height: RectangleSize.height,
                                  },
                                })
                              );

                              setAvailableWidth(
                                RectangleSize.width - currentWidth
                              );
                            }}
                            onDropRejected={(file) =>
                              fileUploadError(file, maxFileSize)
                            }
                            onCancel={Function.prototype}
                          />
                        </Col>
                        <Col>
                          {values.images.map((image, index) => {
                            return (
                              <CropUpload
                                {...{
                                  imgSrc: image.path,
                                  originalSrc: image.orignalPath,
                                  fileName: image.fileName,
                                  cropData: image.cropData,
                                  rectangleSize: RectangleSize,
                                  locked: false,
                                  keepRectangleHeight: true,
                                  maxCropWidth:
                                    availableWidth + image.cropData.width,
                                  onRemoveImage: () => {
                                    setAvailableWidth(
                                      RectangleSize.width -
                                        calculateUsingWidth(
                                          values.images
                                            .slice(0, index)
                                            .concat(
                                              values.images.slice(index + 1)
                                            )
                                        )
                                    );
                                    arrayHelpers.remove(index);
                                  },
                                  onCroppedImage: (cropData) => {
                                    arrayHelpers.replace(index, {
                                      ...image,
                                      cropData,
                                    });

                                    setAvailableWidth(
                                      RectangleSize.width -
                                        calculateUsingWidth(
                                          values.images
                                            .slice(0, index)
                                            .concat({ ...image, cropData })
                                            .concat(
                                              values.images.slice(index + 1)
                                            )
                                        )
                                    );
                                  },
                                }}
                              />
                            );
                          })}
                        </Col>
                      </Fragment>
                    )}
                  </FieldArray>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        );
      }}
    </Formik>
  );
};
